import React, { useEffect } from "react";
// import * as L from "leaflet";
// import "leaflet/dist/leaflet.css";
import "./App.css";
import { Scene, RasterLayer, Zoom, PointLayer, MouseLocation, Fullscreen, LayerPopup } from "@antv/l7";
import { Map } from '@antv/l7-maps';

function App() {
  const dataList = [
    {
      "id": "5011000000404",
      "name": "铁路新村(华池路)",
      "longitude": 121.4316962,
      "latitude": 31.26082325,
      "unit_price": 71469.4,
      "count": 2
    },
    {
      "id": "5011000002716",
      "name": "金元坊",
      "longitude": 121.3810096,
      "latitude": 31.25302026,
      "unit_price": 47480.5,
      "count": 2
    },
    {
      "id": "5011000003403",
      "name": "兰溪路231弄",
      "longitude": 121.4086229,
      "latitude": 31.25291206,
      "unit_price": 55218.4,
      "count": 2
    },
    {
      "id": "5011000003652",
      "name": "兰溪公寓",
      "longitude": 121.409227,
      "latitude": 31.251014,
      "unit_price": 55577.8,
      "count": 2
    },
    {
      "id": "5011000004139",
      "name": "梅岭新村",
      "longitude": 121.400946,
      "latitude": 31.24946565,
      "unit_price": 63028.1,
      "count": 2
    },
    {
      "id": "5011000005647",
      "name": "石泉路140弄",
      "longitude": 121.4318415,
      "latitude": 31.25682515,
      "unit_price": 52256.3,
      "count": 2
    },
    {
      "id": "5011000006479",
      "name": "中山北路2165弄",
      "longitude": 121.4350523,
      "latitude": 31.25364239,
      "unit_price": 55129.4,
      "count": 2
    },
    {
      "id": "5011000008328",
      "name": "光新路430弄",
      "longitude": 121.4374976,
      "latitude": 31.26298493,
      "unit_price": 49397.2,
      "count": 2
    },
    {
      "id": "5011000004973",
      "name": "旬阳新村",
      "longitude": 121.431054,
      "latitude": 31.259485,
      "unit_price": 58836.4,
      "count": 3
    },
    {
      "id": "5011000005863",
      "name": "岚皋路300弄",
      "longitude": 121.4290778,
      "latitude": 31.2587194,
      "unit_price": 56944.2,
      "count": 3
    },
    {
      "id": "5011000006645",
      "name": "光复西路145弄",
      "longitude": 121.4375783,
      "latitude": 31.25329386,
      "unit_price": 63652.3,
      "count": 3
    },
    {
      "id": "5011000011530",
      "name": "祥和大厦",
      "longitude": 121.3839187,
      "latitude": 31.25161362,
      "unit_price": 41029.4,
      "count": 3
    },
    {
      "id": "5011000013673",
      "name": "真光十小区",
      "longitude": 121.395288,
      "latitude": 31.256758,
      "unit_price": 41900.9,
      "count": 3
    },
    {
      "id": "5011000013938",
      "name": "真光新秀",
      "longitude": 121.396422,
      "latitude": 31.260857,
      "unit_price": 49490.7,
      "count": 3
    },
    {
      "id": "5011000017097",
      "name": "陆三小区",
      "longitude": 121.4259304,
      "latitude": 31.24930686,
      "unit_price": 56632.3,
      "count": 3
    },
    {
      "id": "5011000017118",
      "name": "樱花苑",
      "longitude": 121.4002071,
      "latitude": 31.25485805,
      "unit_price": 49650.2,
      "count": 3
    },
    {
      "id": "5011000017635",
      "name": "宁馨家园",
      "longitude": 121.3988072,
      "latitude": 31.25289796,
      "unit_price": 61407.7,
      "count": 3
    },
    {
      "id": "5011000020263",
      "name": "南大街22弄",
      "longitude": 121.4090896,
      "latitude": 31.25320726,
      "unit_price": 52482.1,
      "count": 3
    },
    {
      "id": "5011000000988",
      "name": "芝川新苑",
      "longitude": 121.409025,
      "latitude": 31.263945,
      "unit_price": 59417.8,
      "count": 4
    },
    {
      "id": "5011000007885",
      "name": "南大街34弄",
      "longitude": 121.4080409,
      "latitude": 31.2535179,
      "unit_price": 49739.4,
      "count": 4
    },
    {
      "id": "5011000016119",
      "name": "万千公寓",
      "longitude": 121.4379659,
      "latitude": 31.26270913,
      "unit_price": 61846.7,
      "count": 4
    },
    {
      "id": "5011000017255",
      "name": "上海星港",
      "longitude": 121.419023,
      "latitude": 31.249904,
      "unit_price": 81443.3,
      "count": 4
    },
    {
      "id": "5011000018021",
      "name": "中岚大楼",
      "longitude": 121.432984,
      "latitude": 31.251381,
      "unit_price": 52858.6,
      "count": 4
    },
    {
      "id": "5011000018102",
      "name": "武宁苑",
      "longitude": 121.424577,
      "latitude": 31.249765,
      "unit_price": 53966.7,
      "count": 4
    },
    {
      "id": "5011000019020",
      "name": "天汇国际",
      "longitude": 121.4115686,
      "latitude": 31.26126002,
      "unit_price": 45380,
      "count": 4
    },
    {
      "id": "5011102208410",
      "name": "三元及地苑",
      "longitude": 121.3760233,
      "latitude": 31.26220092,
      "unit_price": 47857.5,
      "count": 4
    },
    {
      "id": "5011000001095",
      "name": "长征家苑",
      "longitude": 121.3949788,
      "latitude": 31.25376373,
      "unit_price": 58117.4,
      "count": 5
    },
    {
      "id": "5011000009828",
      "name": "中环财富杰座",
      "longitude": 121.378574,
      "latitude": 31.25527816,
      "unit_price": 26802.3,
      "count": 5
    },
    {
      "id": "5011000013807",
      "name": "华轩大厦",
      "longitude": 121.375539,
      "latitude": 31.258582,
      "unit_price": 23147.8,
      "count": 5
    },
    {
      "id": "5011000014493",
      "name": "金莲坊",
      "longitude": 121.3802448,
      "latitude": 31.24989653,
      "unit_price": 48458.5,
      "count": 5
    },
    {
      "id": "5011000017050",
      "name": "曹杨家园",
      "longitude": 121.4101947,
      "latitude": 31.26469667,
      "unit_price": 56991.5,
      "count": 5
    },
    {
      "id": "5011000018096",
      "name": "颐宁苑",
      "longitude": 121.415998,
      "latitude": 31.252321,
      "unit_price": 62755.7,
      "count": 5
    },
    {
      "id": "5011000018172",
      "name": "城公大厦",
      "longitude": 121.4383528,
      "latitude": 31.26001489,
      "unit_price": 56813.7,
      "count": 5
    },
    {
      "id": "509977363956217",
      "name": "中骏天悦",
      "longitude": 121.409065,
      "latitude": 31.260569,
      "unit_price": 109279,
      "count": 5
    },
    {
      "id": "5011000015683",
      "name": "市政馨苑",
      "longitude": 121.420237,
      "latitude": 31.259543,
      "unit_price": 60397.9,
      "count": 6
    },
    {
      "id": "5011000003824",
      "name": "星光域",
      "longitude": 121.413902,
      "latitude": 31.264414,
      "unit_price": 91223.7,
      "count": 7
    },
    {
      "id": "5011000014462",
      "name": "大隆花苑",
      "longitude": 121.4388425,
      "latitude": 31.26340479,
      "unit_price": 52720.9,
      "count": 7
    },
    {
      "id": "5011000015186",
      "name": "光新三村",
      "longitude": 121.43749,
      "latitude": 31.263028,
      "unit_price": 50524.7,
      "count": 7
    },
    {
      "id": "5011000015629",
      "name": "平民后村",
      "longitude": 121.43489,
      "latitude": 31.256305,
      "unit_price": 59470,
      "count": 7
    },
    {
      "id": "5011000015825",
      "name": "真光新村",
      "longitude": 121.397285,
      "latitude": 31.260535,
      "unit_price": 48991.6,
      "count": 7
    },
    {
      "id": "5011000018225",
      "name": "半岛花园",
      "longitude": 121.441891,
      "latitude": 31.253607,
      "unit_price": 73694.6,
      "count": 7
    },
    {
      "id": "5011000008852",
      "name": "真西新村第二小区",
      "longitude": 121.402254,
      "latitude": 31.251552,
      "unit_price": 55383.5,
      "count": 8
    },
    {
      "id": "5011000017348",
      "name": "嘉秀坊",
      "longitude": 121.3750242,
      "latitude": 31.26059388,
      "unit_price": 47246.6,
      "count": 10
    },
    {
      "id": "5011000018056",
      "name": "真情公寓",
      "longitude": 121.3909318,
      "latitude": 31.26018159,
      "unit_price": 56255.2,
      "count": 10
    },
    {
      "id": "5011000011380",
      "name": "清涧七街坊",
      "longitude": 121.3922347,
      "latitude": 31.2646608,
      "unit_price": 46948.6,
      "count": 11
    },
    {
      "id": "5011000015943",
      "name": "新体育广场",
      "longitude": 121.432549,
      "latitude": 31.26324211,
      "unit_price": 30852.9,
      "count": 11
    },
    {
      "id": "5011000014039",
      "name": "清涧六街坊",
      "longitude": 121.395639,
      "latitude": 31.263124,
      "unit_price": 45790.7,
      "count": 12
    },
    {
      "id": "5011000015761",
      "name": "曹杨八村",
      "longitude": 121.4120603,
      "latitude": 31.25113592,
      "unit_price": 50921.7,
      "count": 18
    },
    {
      "id": "5011000017518",
      "name": "海棠苑",
      "longitude": 121.398276,
      "latitude": 31.254232,
      "unit_price": 49380.8,
      "count": 18
    },
    {
      "id": "5011000012360",
      "name": "嘉善坊",
      "longitude": 121.3811297,
      "latitude": 31.25881044,
      "unit_price": 41048.3,
      "count": 20
    }
  ]

  // L7无底图-栅格瓦片加载
  const l7Map = () => {
    // 初始化scene图层，无地图底图，Map模式。
    const scene = new Scene(
      {
        id: "map",
        map: new Map(
          {
            center: [116.37760014582538, 39.99115],
            // center: [121.47803, 31.18],
            zoom: 8,
            pitch: 20, // 地图俯仰角
          }
        ),
        logoVisible: false, //隐藏AntV-L7的logo
      }
    )

    // 瓦片数据图层
    const layerTiles = new RasterLayer(
      { zIndex: 1 },
    ).source(
      // "osm/{z}/{x}/{y}.jpg", {
        "bj/{z}/{x}/{y}.png", {
      parser: {
        type: "rasterTile",
        tileSize: 256,
        maxZoom: 16,
        // extent: [-180, -85.0511, 179, 85.0511],

      }
    })

    // 点数据图层
    const layerPoints = new PointLayer({})
      .source(dataList, {
        parser: {
          type: 'json',
          x: 'longitude',
          y: 'latitude'
        }
      })
      .shape('simple')
      .size('unit_price', [10, 25])
      .active(true)
      .color('name', ['#5B8FF9', '#5CCEA1', '#5D7092', '#F6BD16', '#E86452'])
      .style({
        opacity: 0.3,
        strokeWidth: 2
      }).animate(true);

    // 点上鼠标悬浮，展示点位信息 
    const layerPopup = new LayerPopup({
      items: [
        {
          layer: layerPoints,
          fields: [
            {
              field: 'name',
              formatValue: (name?: string) => name?.trim() ?? '-',
            },
          ],
        },
      ],
      trigger: 'hover',
    });
    scene.addPopup(layerPopup);

    // 显示当前地图缩放控件位置及缩放等级
    const ctl = new Zoom({ position: 'leftcenter', showZoom: true })

    scene.on('loaded', () => {
      scene.addLayer(layerTiles);
      scene.addLayer(layerPoints);
      scene.addControl(ctl);

      // 鼠标位置信息
      const mouseLocation = new MouseLocation({
        transform: (position) => {
          return position;
        },
      });
      scene.addControl(mouseLocation);

      // 全屏控件
      const fullscreen = new Fullscreen({
        btnText: '全屏',
        exitBtnText: '退出全屏',
      });
      scene.addControl(fullscreen);
    });

    return scene;
  }

  // leaflet底图模式
  // const leafletMap = () => {
  //   const map = L.map("map", {
  //     minZoom: 7,
  //   }).setView([35.19115702907039, 118.87760014582538], 9);

  //   // 离线加载地图底图 osm文件夹放在public文件夹下
  //   L.tileLayer("osm/{z}/{x}/{y}.jpg", {
  //     minZoom: 9,
  //     maxZoom: 12,
  //     attribution: "© Tianditu contributors",
  //   }).addTo(map);

  //   // 左下角添加地图level级别提示
  //   // L.control.scale({ position: 'bottomright', imperial: false }).addTo(map);


  //   // 从LatLng点的数组中创建一条红色的折线
  //   // const latlngs = [
  //   //   L.latLng(35.51, 120.68),
  //   //   L.latLng(35.77, 120.43),
  //   //   L.latLng(35.04, 120.2),
  //   // ];
  //   // var polyline = L.polyline(latlngs, {color: "red"}).addTo(map);
  //   // map.fitBounds(polyline.getBounds());

  //   // 创建气泡
  //   // const latlngs = [
  //   //   L.latLng(35.40307758164985, 119.32662937053286),
  //   //   L.latLng(35.05144569136434, 118.40930730493513),
  //   //   L.latLng(35.420404527770614, 118.63870093456084),
  //   // ];

  //   // L.circleMarker(latlngs[0], { radius: 40, fillColor: "red" })
  //   //   .bindTooltip("This is a tooltip")
  //   //   .addTo(map);
  //   // L.circleMarker(latlngs[1], { radius: 20, fillColor: "yellow" })
  //   //   .bindTooltip("this is us")
  //   //   .addTo(map);
  //   // L.circleMarker(latlngs[2], {
  //   //   radius: 30,
  //   //   fillColor: "black",
  //   //   fillOpacity: 0.5,
  //   // })
  //   //   .bindTooltip("hall of fame")
  //   //   .addTo(map);

  //   // 点击地图自动拾取当前经纬度
  //   // map.on("click", function (e) {
  //   //   var latlng = e.latlng;
  //   //   console.log(latlng.lat + ", " + latlng.lng);
  //   // });

  //   return map;
  // }

  useEffect(() => {
    const scene = l7Map();
    // let map = leafletMap();

    return () => {
      // 组件卸载时候清除地图图层 - 不然会报错：图层已经初始化过。

      // map.remove(); // leaflet底图时候放开
      scene.removeAllLayer(); // L7 map底图时候放开 
      scene.destroy(); // L7 map底图时候放开
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app">
      <div id="map"></div>
    </div>
  );
}

export default App;
